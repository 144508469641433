<template lang="pug">
form-input-container(
  :label="label"
  :message="message"
  :message-type="messageType"
)
  el-date-picker.date-picker(
    type="date"
    format="DD/MM/YYYY"
    :teleported="teleported"
    :value-format="format"
    :shortcuts="shortcuts"
    :clearable="clearable"
    :model-value="modelValue"
    :placeholder="placeholder"
    :disabled-date="disabledDate"
    @change="$emit('change', $event)"
    @update:modelValue="$emit('update:modelValue', $event)"
  )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";

export default defineComponent({
  name: "DatePicker",
  components: {
    FormInputContainer,
  },
  emits: [
    'change',
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    placeholder: {
      type: String,
      default: 'Выберите дату',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabledDate: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String as PropType<'error'|'info'>,
      default: 'info',
    },
    format: {
      type: String,
      default: '',
    },
    shortcuts: {
      type: Array,
      default: () => [],
    },
    teleported: {
      type: Boolean,
      default: true,
    },
  }
})
</script>

<style lang="scss">
.date-picker {
  width: inherit !important;
  --el-input-height: 34px;
  --el-input-text-color: var(--main-text-color);
  --el-input-focus-border-color: var(--input-border-color-active);
}
</style>
